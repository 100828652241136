<template>
    <div class="index-bg-box">
        <img src="@/assets/image/index/bg.png" class="w-100p">
        <div class="color-white index-content">
            <div class="index-content-title">企业技术服务提供者</div>
            <div class="index-content-desc">网络安全 / 软件开发 / 广告传媒 / 财税服务</div>
            <div class="index-imgs-box">
                <img src="@/assets/image/index/bg-img-1.png" alt="网络安全" @click="jump('/networkSecurity')">
                <img src="@/assets/image/index/bg-img-2.png" alt="软件开发" @click="jump('/softwareDevelopment')">
                <img src="@/assets/image/index/bg-img-3.png" alt="广告传媒" @click="jump('/companyService')">
                <img src="@/assets/image/index/bg-img-4.png" alt="财税服务" @click="jump('/companyService')">
            </div>
        </div>
    </div>
</template>

<script lang='ts'>
    import { defineComponent,ref } from 'vue'
    import { useRouter } from 'vue-router'
    export default defineComponent({
        setup(){
            const router = useRouter()
            const jump = (url:string) => {
                console.log('11')
                router.push(url)
            }
            return{
                jump
            }
        }
    });
</script>
