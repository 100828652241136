
    import { defineComponent,ref } from 'vue'
    import { useRouter } from 'vue-router'
    export default defineComponent({
        setup(){
            const router = useRouter()
            const jump = (url:string) => {
                console.log('11')
                router.push(url)
            }
            return{
                jump
            }
        }
    });
