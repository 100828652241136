<template>
    <div class="mobile-content">
        <div class="w-100p position-relative overflow-h">
            <img src="@/assets/image/index/bg.png" class="w-100p">
            <div class="mobile-content-bg">
                <div>企业技术服务提供者</div>
                <div>网络安全/软件开发/广告传媒/财税服务</div>
            </div>
        </div>
        <div>
            <div class="d-flex-wrap p-b-180 p-t-30">
                <div class="w-50p">
                    <img src="@/assets/image/index/bg-img-1.png" class="display-block margin-auto w-85p" alt="网络安全" @click="jump('/networkSecurity')">
                </div>
                <div class="w-50p">
                    <img src="@/assets/image/index/bg-img-2.png" class="display-block margin-auto w-85p" alt="软件开发" @click="jump('/softwareDevelopment')">
                </div>
                <div class="w-50p">
                    <img src="@/assets/image/index/bg-img-3.png" class="display-block margin-auto w-85p" alt="广告传媒" @click="jump('/companyService')">
                </div>
                <div class="w-50p">
                    <img src="@/assets/image/index/bg-img-4.png" class="display-block margin-auto w-85p" alt="财税服务" @click="jump('/companyService')">
                </div>
            </div>
        </div>
    </div>
</template>

<script lang='ts'>
    import { defineComponent,ref } from 'vue'
    import { useRouter } from 'vue-router'
    export default defineComponent({
        setup(){
            const router = useRouter()
            const jump = (url:string) => {
                console.log('11')
                router.push(url)
            }
            return{
                jump
            }
        }
    });
</script>
